import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Drawer } from '@mantine/core';

import { storageKeys } from '@chess-class/sandbox/consts';
import { useLogout } from '@chess-class/sandbox/hooks';
import { SignOutIcon } from '@chess-class/sandbox/icons';

import { sidebars } from '~/consts/sidebars';
import { usePageMetaContext } from '~/context/PageMetaContext';
import { useStudentContext } from '~/context/StudentContext';

import { ChessNavLink } from '../organisms/SideNavbar';

type IProps = {
  closeDrawer: () => void;
  drawerOpened: boolean;
  languages: string[];
};

export const DrawerMenu: FC<IProps> = ({ closeDrawer, drawerOpened, languages }) => {
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const { openMenuKeys, selectedMenuKeys } = usePageMetaContext();
  const { logout } = useLogout();
  const { isFirstClass, school } = useStudentContext();

  const links = useMemo(
    () =>
      sidebars({ isFirstClass }).map((item) => (
        <ChessNavLink
          item={item}
          key={item.key}
          openMenuKeys={openMenuKeys}
          selectedMenuKeys={selectedMenuKeys}
        />
      )),
    [language, openMenuKeys, selectedMenuKeys, isFirstClass],
  );

  const isDev = process.env.REACT_APP_API_URL.includes('api.dev');

  const filteredLanguages = () => {
    if (isDev) {
      return languages;
    } else {
      return languages.filter((lan) =>
        ['ru', school?.educationFormat == 'GENERAL' ? 'kz' : 'en'].includes(lan),
      );
    }
  };

  return (
    <Drawer
      className="bg-[#11122C]"
      closeButtonProps={{ size: '24px' }}
      onClick={closeDrawer}
      onClose={closeDrawer}
      opened={drawerOpened}
      padding="md"
      size="100%"
      zIndex={1000000}
    >
      <div className="!border-none h-full">
        <div className="p-8">{links}</div>
        <div className="px-8">
          <Divider
            className="text-base text-[#B5B8C8] mb-4"
            label={<p className="mb-0 text-base">{t('chooseLanguage')}</p>}
            labelPosition="center"
            size="xs"
            variant="dashed"
          />
          <div className="grid grid-cols-3 gap-3">
            {filteredLanguages().map((locale) => (
              <Button
                className="hover:text-primary bg-[#24254E] font-medium hover:font-semibold p-[12px]"
                key={`localization-${locale}`}
                leftIcon={
                  <img
                    alt={locale}
                    className="w-6 h-6 object-contain"
                    src={`/images/countries/512x512/${locale}.png`}
                  />
                }
                onClick={() => {
                  changeLanguage(locale).then(() =>
                    localStorage.setItem(storageKeys.language, locale),
                  );
                  closeDrawer();
                }}
              >
                {locale.toUpperCase()}
              </Button>
            ))}
          </div>
          <Button
            className="w-full mt-4 bg-[#11122C]"
            leftIcon={<SignOutIcon className="text-gray-300" />}
            onClick={logout}
          >
            {t('exit')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
