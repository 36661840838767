import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Menu } from '@mantine/core';

import { storageKeys } from '@chess-class/sandbox/consts';
import { ChevronDownIcon, WorldIcon } from '@chess-class/sandbox/icons';

import { StudentContext } from '~/context/StudentContext';

type IProps = {
  languages: string[];
};

export const LanguageDropdown: FC<IProps> = ({ languages }) => {
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const { school, student } = useContext(StudentContext);
  const [searchParams] = useSearchParams();
  const languageQuery = searchParams.get('lang');

  useEffect(() => {
    if (languageQuery) {
      changeLanguage(languageQuery);
      localStorage.setItem(storageKeys.language, searchParams.get('lang') as string);
    }
    if (!languageQuery && student?.language && !localStorage.getItem(storageKeys.language)) {
      localStorage.setItem(storageKeys.language, student.language);
      changeLanguage(student.language);
    }
    if (!languages.includes(language)) {
      localStorage.setItem(storageKeys.language, 'ru');
      changeLanguage('ru');
    }
  }, [languages, student, languageQuery]);

  const isDev = process.env.REACT_APP_API_URL.includes('api.dev');

  const filteredLanguages = () => {
    if (isDev) {
      return languages;
    } else {
      return languages.filter((lan) =>
        ['ru', school?.educationFormat == 'GENERAL' ? 'kz' : 'en'].includes(lan),
      );
    }
  };

  return (
    <Menu classNames={{ dropdown: 'rounded-lg mt-4' }} keepMounted>
      <Menu.Target>
        <Button
          className="p-2 text-gray-500 border-gray-101 capitalize"
          leftIcon={<WorldIcon />}
          rightIcon={<ChevronDownIcon className="rotate-180 text-[#a3a3a3]" />}
          variant="outline"
        >
          {language}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label className="font-semibold text-gray-500"> {t('chooseLanguage')}</Menu.Label>

        <Menu.Divider />

        {filteredLanguages().map((locale) => (
          <Menu.Item
            className="hover:text-primary font-medium hover:font-semibold"
            icon={
              <img
                alt={locale}
                className="w-6 h-6 object-contain"
                src={`/images/countries/512x512/${locale}.png`}
              />
            }
            key={`localization-${locale}`}
            onClick={() => {
              localStorage.setItem(storageKeys.language, locale);
              window.location.reload();
            }}
          >
            {locale.toUpperCase()}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
